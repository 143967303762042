<template>
    <v-row>
        <template
            v-for="({ count, label, icon, colorOption }, index) in counts"
        >
            <v-col :key="index">
                <psi-stats-card
                    hide-actions
                    :title="label"
                    :stat="count"
                    :color-option="colorOption"
                    :icon="icon"
                ></psi-stats-card>
            </v-col>
        </template>
    </v-row>
</template>
<script>
import WithCrimeRecords from "@components/screen/mixins/WithCrimeRecords";
export default {
    name: "crime-summary",
    mixins: [WithCrimeRecords],
    components: {},
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {
        this.records = this.items.filter(
            (item) => this.$_.get(item, "disposition", "") !== "DISMISSED"
        );
    },
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>