var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _vm._l(_vm.counts, function(ref, index) {
        var count = ref.count
        var label = ref.label
        var icon = ref.icon
        var colorOption = ref.colorOption
        return [
          _c(
            "v-col",
            { key: index },
            [
              _c("psi-stats-card", {
                attrs: {
                  "hide-actions": "",
                  title: label,
                  stat: count,
                  "color-option": colorOption,
                  icon: icon
                }
              })
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }